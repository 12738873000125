import MpApiClient from "./httpClient";
import v3Client from "./v3Client";

/**
 * GET GiddyUp internal offers list.
 * These offers should contain customDomainPrefix among other useful properties.
 *
 * @returns {Object<Http Response>} - return an http response where data contains gu offers internal configurations
 */
const getOffersList = () => MpApiClient.get('/offers');

/**
 * GET the runnable offers of an affiliate.
 *
 * @param {String} encryptedToken - the
 * @returns {Object<Http Response>} - data will contain runnable offers.
 */
const getMarketingPartnerRunnableOffers = (encryptedToken) => {
  return v3Client.get(`/everflow/offers-runnable/${encryptedToken}/`);
}

/**
 * GET offers urls of an affiliate.
 *
 * @param {String} encryptedToken - the
 * @returns {Object<Http Response>} - data will contain offers urls.
 */
const getMarketingPartnerOfferUrls = (encryptedToken, offerId, query = {}) => {
  const page = parseInt(query.page) || 1;
  const pageSize = parseInt(query?.pageSize) || 2000;
  return MpApiClient.get(`/${encryptedToken}/efo/offersUrls/${offerId}/?page=${page}&page_size=${pageSize}`);
}

/**
 * GET offers url for an affiliate
 *
 * @param {String} encryptedToken - the encrypted token
 * @param {String} offerId - the offer Id
 * @param {String} offerId - Parameters accepted by everflow API:
 * https://developers.everflow.io/docs/network/tracking_links/#generate-smartlink-tracking-link
 *
 * @returns {Object<Http Response>} - data will contain offer url.
 */
const getMarketingPartnerDirectLink = (encryptedToken, offerId, data = {}) => {
  return MpApiClient.post(`/${encryptedToken}/offers/${offerId}/tracking/clicks`, data, {});
}

/**
 * GET offers url for an affiliate
 *
 * @param {String} encryptedToken - the encrypted token
 * @param {String} offerId - the offer Id
 * @param {String} offerId - Parameters accepted by everflow API:
 * https://developers.everflow.io/docs/network/tracking_links/#generate-smartlink-tracking-link
 *
 * @returns {Object<Http Response>} - data will contain offer url.
 */
const getMarketingPartnerRedirectLink = (encryptedToken, offerId, data = {}) => {
  data.is_redirect_link = true;
  return MpApiClient.post(`/${encryptedToken}/offers/${offerId}/tracking/clicks`, data, {});
}

/**
 * GET offer configurations of an affiliate.
 *
 * @param {String} encryptedToken - the
 * @returns {Object<Http Response>} - data will contain configuration info.
 */
const getMarketingPartnerConfigs = (encryptedToken) => {
  return MpApiClient.get(`/${encryptedToken}/offers/configs`);
}

/**
 * GET offer configurations of an affiliate.
 *
 * @param {String} encryptedToken - the
 * @returns {Object<Http Response>} - data will contain configuration info.
 */
const getMarketingPartnerBrands = (encryptedToken) => {
  return MpApiClient.get(`/${encryptedToken}/brands`);
}

/**
 * GET shared domains of an affiliate.
 *
 * @param {String} encryptedToken - the
 * @returns {Object<Http Response>} - data will contain shared domain data.
 */
const getMarketingPartnerSharedOffers = (encryptedToken) => {
  return MpApiClient.get(`/${encryptedToken}/shared-domains`);
}

/**
 * GET zone ID for domain.
 *
 * @param {String} encryptedToken - the
 * @returns {Object<Http Response>} - data will contain zone id.
 */
export const checkDomainV3 = (domainUID) => {
  return v3Client.get(`/domains/${domainUID}/dns-status`);
}

/**
 * GET zone ID for domain.
 *
 * @param {String} encryptedToken - the
 * @returns {Object<Http Response>} - data will contain zone id.
 */
export const getZoneIdForDomain = (encryptedToken, domainName) => {
  return MpApiClient.get(`/${encryptedToken}/cloudflare/${domainName}`);
}

/**
 * The domain registration takes a long time, and it's best for us to have it warmed up.
 * This will be called to get the container warm before calling it for actual process.
 * @returns {Promise<HttpResponse>}
 */
export const getDomainFunctionsWarmedUp = async () => {
  try {
    await MpApiClient.get('/warmers/exclusive-domain-registration');
    return true;
  } catch (error) {
    return false;
  }
}

export default {
  getOffersList,
  getMarketingPartnerRunnableOffers,
  getMarketingPartnerOfferUrls,
  getMarketingPartnerDirectLink,
  getMarketingPartnerRedirectLink,
  getMarketingPartnerConfigs,
  getMarketingPartnerBrands,
  getMarketingPartnerSharedOffers,
  getZoneIdForDomain
};
